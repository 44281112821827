import { render, staticRenderFns } from "./LobbyCard.vue?vue&type=template&id=722e2984&scoped=true"
import script from "./LobbyCard.vue?vue&type=script&lang=ts"
export * from "./LobbyCard.vue?vue&type=script&lang=ts"
import style0 from "./LobbyCard.vue?vue&type=style&index=0&id=722e2984&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722e2984",
  null
  
)

export default component.exports