import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Notifications from "vue-notification";
import Mouseover from "vue-mouseover";
import "@/components";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
} from "vee-validate";
import "vue-advanced-cropper/dist/style.css";
import VueSocketIOExt from "vue-socket.io-extended";

import socket from "./socket";
// import * as moment from 'moment';
import moment from "moment";
import * as vuemoment from "vue-moment";
Vue.use(vuemoment, {
  moment,
});
import "moment/locale/ru";
//(number < 10 ? "0" : "") +
moment.updateLocale("ru", {
  relativeTime: {
    future: "через %s",
    past: "%s назад",
    s: function (number) {
      return number + " сек.";
    },
    m: "1 мин.",
    mm: function (number) {
      return number + " мин.";
    },
    h: "1 ч.",
    hh: "%d ч.",
    d: "1 д.",
    dd: "%d д.",
    M: "1 м.",
    MM: "%d м.",
    y: "1 г.",
    yy: "%d г.",
  },
});

Vue.config.productionTip = false;

import mdiVue from "mdi-vue";
import * as mdijs from "@mdi/js";
import vuetify from "./plugins/vuetify";
Vue.use(VueSocketIOExt, socket, { store });

import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);
mdijs["mdiDiscord"] =
  "M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z";
Vue.use(Mouseover);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(Notifications);
Vue.use(mdiVue, {
  icons: mdijs,
});
Vue.directive("mclass", {
  bind: function (el: any, binding: any) {
    if (!store.state.isMobile) return;
    const classes = binding.value.split(" ");
    for (let i = 0; i < classes.length; i++) {
      const element = classes[i];
      el.classList.add(element);
    }
  },
});

Vue.directive("click-outside", {
  bind: function (el: any, binding: any, vnode: any) {
    el.clickOutsideEvent = function (event: any) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener(
      "click",
      (el as unknown as { clickOutsideEvent: any }).clickOutsideEvent
    );
  },
});

Vue.directive("mobileref", {
  inserted: function (el: any, binding: any) {
    if (!store.state.isMobile) return;

    const parent = document.getElementById(binding.value);
    parent!.appendChild(el);
  },
});

(Array.prototype as any).diff = function (arr2) {
  const ret = [];
  for (const i in this) {
    if (arr2.indexOf(this[i]) > -1) {
      (ret as any).push(this[i]);
    }
  }
  return ret;
};

// Vue.directive("mstyle", {
//   bind: function (el: any, binding: any) {
//     console.log(123);
//     if (!store.state.isMobile) return;
//     const styles = binding.value.replaceAll(" ", "").split(";");
//     for (let i = 0; i < styles.length; i++) {
//       const element = styles[i];
//       console.log(element);
//       el.style[element.split(":")[0]] = element.split(":")[1];
//       console.log(el.style);
//     }
//   },
// });
import { email } from "vee-validate/dist/rules";
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  message: "Это поле необходимо",
  computesRequired: true,
});
extend("min", {
  validate(value, { length }) {
    return value.replaceAll(" ", "").length >= length;
  },
  params: ["length"],
  message: "Минимальная длинна {length} символов",
});
extend("max", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "Максимальная длинна {length} символов",
});
extend("username", {
  validate(value) {
    return value.match(/^[0-9-_.\w]+$/); //_-
  },
  message: "Имя содержит запрещённые символы. Допустимы: a-Z, 0-9, - _ .", //, -, _
});
extend("password", {
  validate(value) {
    return value.match(/^[!?(){}@#$%^&*\w]+$/);
  },
  message:
    "Пароль содержит запрещённые символы. Допустимы: a-Z, ! ? @ # $ % ^ & * () {}",
});
extend("email", {
  ...email,
  message: "Введите действительный адрес почты",
});

setInteractionMode("aggressive");
// setInteractionMode("betterEager", ({ errors, flags }) => {
//   // become slightly aggressive if the field is invalid.
//   console.log(flags.invalid);
//   // if (errors.length && flags.invalid) return { debounce: 0 };
//   if (errors.length) {
//     console.log("asdasd");
//     return {
//       on: ["input"],
//       debounce: 1000,
//     };
//   }

//   // validate immediately after leaving the field.
//   return {
//     on: ["input"],
//     debounce: 0,
//   };
// });

import VueYandexMetrika from "vue-yandex-metrika";
Vue.use(VueYandexMetrika, {
  id: 92747920,
  router: router,
  env: "production", //'production', //process.env.NODE_ENV
  // scriptSrc: "https://storage.easygaming.su/metrika.js",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export const bus = new Vue();
export const socketio = socket;
