
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MemberCount",

  props: {
    block: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    hint: {
      type: String as PropType<string | boolean>,
    },
    value: Number,
    autofocus: Boolean,
    error: String,
    type: {
      type: String,
      default: "text",
    },
  },

  data() {
    return {};
  },
  computed: {
    inputVal: {
      get(): number {
        return this.value as number;
      },
      set(val: string) {
        if (val == "") return this.$emit("input", val);
        const num = parseFloat(val);
        if (num < 1 || num > 99 || isNaN(num)) return;
        this.$emit("input", num);
      },
    },
  },
  methods: {},
});
