
import { GameService } from "@/services/game.service";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      dialog: false,

      imagesTemplate: [
        {
          _id: 1,
          url: "https://files.libertycity.net/download/gta5_dodge/thumbs/2017-03/2010-dodge-challenger-srt8-rampage_1685996822_857099.jpg",
          category: "races",
        },
        {
          _id: 2,
          url: "https://files.libertycity.net/download/gta5_lamborghini/fulls/2017-10/zlayworks-lamborghini-zentenario-add-on_1685998337_450124.jpg",
          category: "races",
        },
        {
          _id: 3,
          url: "https://i.pinimg.com/originals/9c/1c/a3/9c1ca3d8cad3460bf7c76aa7a3579789.jpg",
          category: "races",
        },
        {
          _id: 4,
          url: "https://files.libertycity.net/download/gta5_bmw/fulls/2019-06/bmw-i8-roadster-ac-schnitzer-add-onoiv_1686001891_175668.jpg",
          category: "races",
        },
        {
          _id: 5,
          url: "https://i.pinimg.com/originals/71/97/40/7197400ad499d5a9dbdb9659cad881a4.jpg",
          category: "races",
        },
        {
          _id: 6,
          url: "https://games.mail.ru/hotbox/content_files/gallery/b1/3c/39ef1c9a.png",
          category: "races",
        },
        {
          _id: 7,
          url: "https://i.pinimg.com/originals/9c/1c/a3/9c1ca3d8cad3460bf7c76aa7a3579789.jpg",
          category: "races",
        },
        {
          _id: 8,
          url: "https://avatars.mds.yandex.net/get-mpic/6219218/img_id7046786471772498984.jpeg/orig",
          category: "shooting",
        },
        {
          _id: 9,
          url: "https://u.kanobu.ru/editor/images/86/3fab49a9-50c1-49e5-bb1d-b4f92acb602f.webp",
          category: "shooting",
        },
        {
          _id: 10,
          url: "https://i.imgur.com/02NxOML.jpg",
          category: "shooting",
        },
        {
          _id: 11,
          url: "https://2karandasha.ru/uploads/images/pictures/2021/08/1894.jpg",
          category: "shooting",
        },
        {
          _id: 12,
          url: "http://gta.riotpixels.com/i/gta5/artworks/gta5-artwork-148-gta-online-smg-shootout.jpg",
          category: "shooting",
        },
      ],
      types: [
        {
          text: "Гонки",
          value: "races",
        },
        {
          text: "Перестрелки",
          value: "shooting",
        },
        {
          text: "Каскадёрские гонки",
          value: "act_races",
        },
        {
          text: "Все фотографии",
          value: "all",
        },
      ],
      images: [] as string[],
      error: "",
    };
  },
  name: "ImageSelect",
  props: {
    block: Boolean,
    value: String,
    label: String,
    lobby: Object,
  },
  computed: {
    selected: {
      get(): string {
        return this.value as string;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getImages() {
      this.error = "Загрузка..";
      this.images = [];
      // console.log(this.lobby.game, " - game");
      GameService.getLobbyImages({
        gameId: this.lobby.game,
        category: this.lobby.category,
        mode: this.lobby.mode,
      })
        .then((res) => {
          this.error = "";
          const images = res.data;
          this.images = (images as any).map((val) =>
            val.name.replace("@1x.webp", "")
          ) as string[]; //this.imagesTemplate.map((val) => val.url);
          if (!this.images[0]) this.error = "Нет изображений";
          return;
        })
        .catch(() => {
          this.error = "Ошибка / Игра не указана";
        });
    },
  },
});
