
import Vue from "vue";
export default Vue.extend({
  name: "LeftMenu",

  props: {
    icon: String,
    block: Boolean,
    to: String,
    active: Boolean,
  },

  methods: {
    onClick() {
      if (this.to) return this.$router.push(this.to);
      this.$emit("click");
    },
  },
});
