import Vue from "vue";

import EZNotification from "@/components/Notification/Notification.vue";
import EZButton from "@/components/Button/Button.vue";
import EZInput from "@/components/Input/Input.vue";
import EZCheckBox from "@/components/CheckBox/CheckBox.vue";
import EZMenuButton from "@/components/MenuButton/MenuButton.vue";
import EZTextField from "@/components/TextField/TextField.vue";
import EZSwitch from "@/components/Switch/Swtich.vue";
import EZSwitchBox from "@/components/SwitchBox/SwitchBox.vue";
import EZImage from "@/components/Image/Image.vue";
import EZLeftMenu from "@/components/LeftMenu/LeftMenu.vue";
import EZDialog from "@/components/Dialog/Dialog.vue";
import EZUploadFile from "@/components/UploadFile/UploadFile.vue";
import EZTag from "@/components/Tag/Tag.vue";
import EZSelect from "@/components/Select/Select.vue";
import EZMultiSelect from "@/components/MultiSelect/MultiSelect.vue";
import EZTagSelect from "@/components/TagSelect/TagSelect.vue";
import EZLobbyCard from "@/components/LobbyCard/LobbyCard.vue";
import EZUserList from "@/components/UserList/UserList.vue";
import EZFloat from "@/components/Float/Float.vue";
import EZImageSelect from "@/components/ImageSelect/ImageSelect.vue";
import EZTooltip from "@/components/ToolTip/ToolTip.vue";
import EZMemberCount from "@/components/MemberCount/MemberCount.vue";

Vue.component("ez-notification", EZNotification);
Vue.component("ez-button", EZButton);
Vue.component("ez-input", EZInput);
Vue.component("ez-checkbox", EZCheckBox);
Vue.component("ez-menubutton", EZMenuButton);
Vue.component("ez-textfield", EZTextField);
Vue.component("ez-switch", EZSwitch);
Vue.component("ez-switchbox", EZSwitchBox);
Vue.component("ez-img", EZImage);
Vue.component("ez-leftmenu", EZLeftMenu);
Vue.component("ez-dialog", EZDialog);
Vue.component("ez-upload", EZUploadFile);
Vue.component("ez-tag", EZTag);
Vue.component("ez-select", EZSelect);
Vue.component("ez-multiselect", EZMultiSelect);
Vue.component("ez-tagselect", EZTagSelect);
Vue.component("ez-lobbycard", EZLobbyCard);
Vue.component("ez-userlist", EZUserList);
Vue.component("ez-float", EZFloat);
Vue.component("ez-imageselect", EZImageSelect);
Vue.component("ez-tooltip", EZTooltip);
Vue.component("ez-membercount", EZMemberCount);
