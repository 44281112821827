
import Vue from "vue";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";

export default Vue.extend({
  name: "Float",
  components: {
    VueBottomSheetVue2,
  },
  props: {
    value: [Boolean, String],
    id: String,
    closeOnClick: Boolean,
  },
  data() {
    return {
      floaterOpen: false,
      opening: false,
    };
  },
  methods: {
    close() {
      if (this.closeOnClick) this.closeFloater();
    },
    async openFloater(user: any, e: any) {
      if (this.$store.state.isMobile) {
        (
          this.$refs[`bottomsheet` + this.id] as unknown as { open: () => void }
        ).open();

        return;
      }

      this.floaterOpen = true;
      this.opening = true;
      await this.$nextTick();
      var activator = (
        this.$refs[`activator${this.id}`] as HTMLDivElement
      ).getBoundingClientRect();

      // console.log(e, "y");
      // console.log(`${e.pageY - activator.height}px`, `${e.clientX + 24}px`);
      await this.$nextTick();
      (this.$refs[`floater${this.id}`] as HTMLDivElement).style.top = `${
        e.pageY - activator.height
      }px`;
      (this.$refs[`floater${this.id}`] as HTMLDivElement).style.left = `${
        e.clientX + 24
      }px`;
      //////

      await this.$nextTick();
      var floater = (
        this.$refs[`floater${this.id}`] as HTMLDivElement
      ).getBoundingClientRect();

      var dif = e.clientY + floater.height - window.innerHeight;
      await this.$nextTick();
      // console.log(dif);
      setTimeout(() => {
        this.opening = false;
      }, 100);

      if (dif < -32) return;

      let modDocument = this.$refs[`floater${this.id}`] as HTMLDivElement;

      modDocument.style.top = `${
        (modDocument.style.top.replace("px", "") as unknown as number) -
        dif -
        32
      }px`;
    },
    async closeFloater() {
      if (this.opening) return;
      this.floaterOpen = false;
      this.opening = false;
    },
  },
  mounted() {
    // this!.$el!.parentNode!.removeChild(this!.$el);
    // (
    //   document.getElementsByClassName("v-main")[0] as HTMLDivElement
    // ).appendChild(this!.$el);
    const el = (this.$refs["bottomsheet" + this.id] as any).$el;
    // console.log(el);
    (
      document.getElementsByClassName("v-main__wrap")[0] as HTMLDivElement
    ).appendChild(el);
  },
  computed: {
    // inputVal: {
    //   get(): boolean | string {
    //     if (typeof this.value == "boolean") return this.value;
    //     return this.value === this.dialogName;
    //   },
    //   set(val: boolean | string): void {
    //     this.$emit("input", val);
    //   },
    // },
  },
});
