<template>
  <div
    :style="`${css}; height: inherit; width: inherit; overflow: hidden`"
    ref="imgDiv"
  >
    <v-img
      :contain="contain"
      :width="width"
      :height="height"
      :style="`${css}`"
      ref="myDiv1"
      @load="
        $emit('loaded');
        loadedOk();
      "
      v-if="!link && !FileNotFound"
      :class="xclass"
      @click="click"
      :gradient="gradient"
      :src="GetSrcPath"
      :srcset="GetSrcSetPath"
      v-on:error="TryFind()"
      :lazy-src="lazySrc"
    >
      <slot></slot>
      <svg
        v-if="ezCopyright"
        style="position: absolute; bottom: 8px; right: 8px"
        width="35.25"
        height="24"
        viewBox="0 0 95 64"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M94.6667 0H0V64H94.6667V42.2986H68.8337L94.6667 7.98046V0ZM58.5187 47.018H90.4711V60.0696H3.99065V4.08672H90.4711L58.5187 47.018Z"
        />
        <path
          d="M61.8573 15.5676H20.6841V23.1157H49.1396L37.6937 39.4699H20.6841V47.018H39.9187L61.8573 15.5676Z"
        />
      </svg>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- <div v-if="!loaded" :style="{ height: height }">
      <span>loading</span>
    </div> -->
    <!-- <v-img
      ref="myDiv2"
      @load="$emit('loaded')"
      v-if="link && !FileNotFound"
      :class="xclass"
      :width="width"
      :height="height"
      @click="click"
      :style="css"
      :gradient="gradient"
      :src="link"
    >
      <slot></slot>
      <svg
        v-if="ezCopyright"
        style="position: absolute; bottom: 8px; right: 8px"
        width="35.25"
        height="24"
        viewBox="0 0 95 64"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M94.6667 0H0V64H94.6667V42.2986H68.8337L94.6667 7.98046V0ZM58.5187 47.018H90.4711V60.0696H3.99065V4.08672H90.4711L58.5187 47.018Z"
        />
        <path
          d="M61.8573 15.5676H20.6841V23.1157H49.1396L37.6937 39.4699H20.6841V47.018H39.9187L61.8573 15.5676Z"
        />
      </svg>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-row> </template
    ></v-img> -->

    <v-row
      :class="`pa-0 ma-0 ${xclass}`"
      justify="center"
      align="center"
      v-if="FileNotFound"
      :style="`height: ${realHeight}; width: ${realWidth}; background: var(--background); ${css}`"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :size="GetAdequateSizeForCloudError(width, height)"
            >mdi-cloud-alert</v-icon
          >
        </template>
        <span>Сервер недоступен</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ez-Image",
  props: {
    css: String,
    src: String,
    contain: Boolean,
    darker: Boolean,
    srcset: {
      type: Array,
      default: () => {
        return [2];
      },
    },
    "aspect-ratio": {
      type: Number,
      default: 1,
    },
    link: {
      type: String,
      default: undefined,
    },
    gradient: {
      type: String,
      default: "",
    },
    ezCopyright: { type: Boolean, default: false },
    xclass: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    let exts = ["webp", "jpg"];
    let format = exts[0];
    let args =
      this.src && this.src.split("?")[1] ? `?${this.src.split("?")[1]}` : "";

    return {
      FileNotFound: false,
      exts,
      format,
      args,
      maxWidth: 100,
      loaded: false,
    };
  },
  watch: {
    src(val) {
      // console.log(val, "src updated");
      this.args =
        this.src && this.src.split("?")[1] ? `?${this.src.split("?")[1]}` : "";
      this.updatePreview(val);
    },
  },
  computed: {
    realHeight() {
      return this.height.includes("px") ? this.height : `${this.height}px`;
    },
    realWidth() {
      return this.width.includes("px") ? this.width : `${this.width}px`;
    },
    lazySrc() {
      var src = this.src;
      if (!src) {
        return ``;
      }
      if (src.startsWith("data:image/")) return src;
      else {
        if (!src.startsWith("http"))
          src = process.env.VUE_APP_API_URL + "/api/" + src;
        if (!src.includes("easygaming.su") && !src.includes("://localhost"))
          return src;
        var link = `${src && src.split("?")[0] ? src.split("?")[0] : src}.jpg`;
        return link;
      }
    },
    GetSrcPath() {
      // console.log("GetSrcPath");
      var src = this.src;
      if (!src) {
        // this.FileNotFound = true;
        return ``;
      }
      if (src.startsWith("data:image/")) return src;
      else {
        if (!src.startsWith("http"))
          src = process.env.VUE_APP_API_URL + "/api/" + src;
        if (!src.includes("easygaming.su") && !src.includes("://localhost"))
          return src;
        var link = `${src && src.split("?")[0] ? src.split("?")[0] : src}@1x.${
          this.format
        }${this.args}`;
        // console.log("end link: " + link, "args: " + this.src);
        return link.replace("http://", "https://");
      }
    },
    GetSrcSetPath() {
      // console.log("GetSrcSetPath");
      var src = this.src;
      if (!src) {
        // this.FileNotFound = true;
        return ``;
      }
      if (!src.startsWith("http"))
        src = process.env.VUE_APP_API_URL + "/api/" + src;

      if (src.startsWith("data:image/")) return null;
      if (!src.includes("easygaming.su") && !src.includes("://localhost"))
        return src;
      else
        return `${src.split("?")[0] ? src.split("?")[0] : src}@1x.${
          this.format
        }${this.args} 1x, ${src.split("?")[0] ? src.split("?")[0] : src}@2x.${
          this.format
        }${this.args} 2x`;
    },
  },

  // mounted() {
  //   setTimeout(() => {
  //     console.log(this.$refs?.myDiv1?.src);
  //     //console.log(this.$refs?.myDiv2?.src, 2);
  //   }, 1);
  // },
  methods: {
    loadedOk() {
      this.loaded = true;
    },
    GetAdequateSizeForCloudError(width, height) {
      const offset = 0.6;
      // console.log(width, height);

      width = width === "100%" ? 99999 : width.replace(/\D/g, "");
      height = height === "100%" ? 99999 : height.replace(/\D/g, "");

      var minNumber = Math.min(width, height) * offset;

      return minNumber;
    },
    updatePreview(val) {
      if (this.src.startsWith("data:image/")) {
        this.srcPath = val;
      }
    },
    click(v) {
      this.$emit("click", v);
    },
    TryFind() {
      let NextExt = this.exts.indexOf(this.format) + 1;
      if (NextExt < this.exts.length) this.format = this.exts[NextExt];
      else this.FileNotFound = true;
    },
  },

  mounted() {
    const w = this.$refs.imgDiv.clientWidth;
    if (w == 0) return;
    this.maxWidth = w;
  },
};
</script>

<style scoped>
.darker {
  filter: brightness(80%);
}
</style>
