import router from "@/router";
import store from "@/store";
import axios from "axios";
let startTime = 0;
let endTime = 0;
class TokenService {
  reqHandler = () => {
    axios.interceptors.request.use(
      (config) => {
        // console.log("req");
        startTime = performance.now();
        const token = this.getLocalAccessToken();
        // console.log(token);
        if (token) {
          config.headers!["x-access-token"] = token;
        }
        return config;
      },
      (error: string) => {
        // console.log(1);
        return Promise.reject(error);
      }
    );
  };

  resHandler = (basePath: string) => {
    axios.interceptors.response.use(
      async (res) => {
        // console.log(res.headers);
        const serverName = res?.headers["server-name"];
        if (serverName) store.state.serverName = serverName;
        endTime = performance.now();
        const responseTime = Math.round(endTime - startTime);
        store.state.ping = responseTime;

        // console.log(res.status);

        return res;
      },
      async (err) => {
        // console.log(3, JSON.stringify(err));
        if (err.message == "Network Error") {
          return;
        }
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/login" && err.response) {
          if (err.response.status === 419 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await axios.post(basePath + "/api/auth/refreshtoken", {
                refreshToken: this.getLocalRefreshToken(),
              });
              const { accessToken } = rs.data;
              this.updateLocalAccessToken(accessToken as string);
              return axios(originalConfig);
            } catch (_error) {
              this.removeUser();
              return Promise.reject(_error);
            }
          }
        }
        if (err.response.status === 401 && router.currentRoute.path != "/sign")
          router.push("/sign");
        return Promise.reject(err);
      }
    );
  };

  getLocalRefreshToken() {
    let user = JSON.parse(localStorage.getItem("user") as string);
    if (!user) user = JSON.parse(sessionStorage.getItem("user") as string);
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    let user = JSON.parse(localStorage.getItem("user") as string);
    if (!user) user = JSON.parse(sessionStorage.getItem("user") as string);

    //console.log(user);
    return user?.accessToken;
  }

  updateLocalAccessToken(token: string) {
    store.commit("updateAccessToken", token);
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user") as string);
  }

  //   setUser(user: string) {
  //     store.commit("savePersistUser", user);
  //   }

  removeUser() {
    store.commit("logout", { expired: 1 });
    router.push({ name: "Sign", params: { expired: "1" } });
  }
}

export default new TokenService();
