
import { defineComponent } from "vue";
export default defineComponent({
  name: "UserList",

  props: {
    loading: Boolean,
    disabled: Boolean,
    user: Object,
    icon: String,
    iconColor: String,
    muted: Boolean,
  },
});
