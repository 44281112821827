export enum ThemesList {
  Light = "light",
  Dark = "dark",
}

type OtherColors = "light-texts-color-contrast" | "light-cards-cards";

type PrimaryColors =
  | "color-primary-default"
  | "color-primary-hover"
  | "color-primary-active"
  | "color-primary-disable"
  | "color-primary-focus"
  | "color-primary-opacity-10"
  | "color-primary-opacity-20";

type SecondaryColors =
  | "color-secondary-default"
  | "color-secondary-hover"
  | "color-secondary-active"
  | "color-secondary-disable"
  | "color-secondary-focus"
  | "color-secondary-opacity-10"
  | "color-secondary-opacity-20";

type ErrorColors =
  | "color-error-default"
  | "color-error-hover"
  | "color-error-active"
  | "color-error-disable"
  | "color-error-focus"
  | "color-error-opacity-10"
  | "color-error-opacity-20";

type SuccessColors =
  | "color-success-default"
  | "color-success-hover"
  | "color-success-active"
  | "color-success-disable"
  | "color-success-focus"
  | "color-success-opacity-10"
  | "color-success-opacity-20";

type WarningColors =
  | "color-warning-default"
  | "color-warning-hover"
  | "color-warning-active"
  | "color-warning-disable"
  | "color-warning-focus"
  | "color-warning-opacity-10"
  | "color-warning-opacity-20";

type InfoColors =
  | "color-info-default"
  | "color-info-hover"
  | "color-info-active"
  | "color-info-disable"
  | "color-info-focus"
  | "color-info-opacity-10"
  | "color-info-opacity-20";

export type VariablesList = Record<
  | OtherColors
  | PrimaryColors
  | SecondaryColors
  | ErrorColors
  | SuccessColors
  | WarningColors
  | InfoColors,
  string
>;
