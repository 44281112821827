
import Vue from "vue";

export default Vue.extend({
  name: "TagSelect",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    values: Array,
    label: {
      type: String,
    },
  },
  data() {
    return {
      // tags: [
      //   "Без цензуры",
      //   "Строго 18+",
      //   "16+",
      //   "Без читов",
      //   "С читами",
      //   "По фану",
      //   "Только PRO",
      // ],
    };
  },
  methods: {
    clickTag(tag: any) {
      const i = this.value.indexOf(tag);
      if (i > -1) this.inputVal.splice(i, 1);
      else this.inputVal.push(tag);
      const arr = this.inputVal;
      this.inputVal = arr;
      // console.log(this.inputVal);
    },
  },
  computed: {
    inputVal: {
      get(): string[] {
        return this.value as string[];
      },
      set(val: { text: string }[]) {
        this.$emit("input", val.sort());
      },
    },
  },
});
