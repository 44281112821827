
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import Vue from "vue";

type VCrop = Element & {
  getResult: () => {
    canvas: {
      toDataURL: () => string;
    };
  };
};

export default Vue.extend({
  name: "UploadFile",
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    roundStencil: Boolean,
    squareStencil: Boolean,
    aspectRatio: {
      type: [Number, String],
      default: 1,
    },
    maxSize: {
      type: Number,
      default: 5,
    },

    formats: {
      type: String,
      default: "jpg, png",
    },
    // value: {
    //   type: [Object, File],
    //   default: () => {
    //     return {
    //       name: "",
    //       size: 0,
    //       type: "",
    //       fileExtention: "",
    //       url: "",
    //       isImage: false,
    //       isUploaded: false,
    //     };
    //   },
    // },
  },
  data() {
    return {
      errors: [] as string[],
      isLoading: false,
      uploadReady: true,
      previewImage: "",
      file: {
        name: "",
        size: 0,
        type: "",
        fileExtention: "",
        url: "",
        isImage: false,
        isUploaded: false,
      },
    };
  },
  mounted() {
    /*
  Listen to all of the drag events and bind an event listener to each
  for the fileform.
*/
    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach((evt: any) => {
      /*
    For each event add an event listener that prevents the default action
    (opening the file in the browser) and stop the propagation of the event (so
    no other elements open the file in the browser)
  */
      (this.$refs.fileform as HTMLDivElement).addEventListener(
        evt,
        (e) => {
          e.preventDefault();
          e.stopPropagation();
        },
        false
      );
    });

    (this.$refs.fileform as HTMLDivElement).addEventListener(
      "drop",
      (e: any) => {
        //for (let i = 0; i < e.dataTransfer.files.length; i++) {
        //this.file = e.dataTransfer.files[i];
        //}
        if (!this.isFileValid(e.dataTransfer.files[0])) return;
        this.file = e.dataTransfer.files[0];
      }
    );

    // setInterval(() => {
    //   console.log(this.file.name, 100);
    // });
  },
  methods: {
    changeImage() {
      if (!this.$refs.cropper) return;
      var canvas = (this.$refs.cropper as VCrop).getResult().canvas;
      let image = canvas.toDataURL();
      this.$emit("stencilChange", image);
    },

    handleFileChange(e: any) {
      this.errors = [];
      if (e.target.files && e.target.files[0]) {
        // Get uploaded file
        if (!this.isFileValid(e.target.files[0])) return;
        let file = e.target.files[0];
        this.previewImage = URL.createObjectURL(file);

        // const fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
        const fileExtention = file.name.split(".").pop();
        // fileName = file.name.split(".").shift(),
        // isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);

        file.fileExtention = fileExtention;
        this.file = file;

        // console.log(this.file, "141");
      }
    },
    isFileSizeValid(fileSize: any, fileName: string) {
      if (fileSize > this.maxSize) {
        this.errors.push(
          `${fileName}: Превышен допустимый размер в ${this.maxSize}Мб`
        );
      }
    },
    isFileTypeValid(fileExtention: any, fileName: string) {
      if (!this.formats.split(", ").includes(fileExtention)) {
        this.errors.push(`${fileName}: Неверный тип файла`);
      }
    },
    isFileValid(file: any) {
      this.isFileSizeValid(
        Math.round((file.size / 1024 / 1024) * 100) / 100,
        file.name.split(".").slice(0, -1)
      );
      this.isFileTypeValid(
        file.name.split(".").pop(),
        file.name.split(".").slice(0, -1)
      );
      if (this.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    hasStencil() {
      return this.roundStencil || this.squareStencil;
    },
    // file: {
    //   get(): any {
    //     return this.value as any;
    //   },
    //   set(val: any) {
    //     console.log(val, " - new val");
    //     this.$emit("input", val);
    //   },
    // },
  },
});
