import Vue from "vue";
import Vuex from "vuex";
import createMultiTabState from "vuex-multi-tab-state";
import socket from "../socket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: undefined as unknown,
    accessToken: "",
    refreshToken: "",
    isMobile:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      ),
    ping: 0,
    ip: "0.0.0.0",
    serverName: "",
    scrollTop: 0,
  },
  getters: {
    localUser: () => {
      let user = localStorage.getItem("user");
      // console.log(user, "<= user");
      if (!user) user = sessionStorage.getItem("user");
      // console.log(user, "<= user");
      return JSON.parse(user as string);
    },
    // loadUser: (state, getters) => {
    //   const user = getters.localUser;
    //   if (user) {
    //     state.user = user;
    //     state.accessToken = user.accessToken;
    //     state.refreshToken = user.refreshToken;
    //   }
    //   return user;
    // },
    accessToken: (state, getters) => {
      if (state.accessToken) return state.accessToken;
      getters.localUser?.accessToken;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
      state.accessToken = data?.accessToken;
      state.refreshToken = data?.refreshToken;
    },
    savePersistUser(state, data) {
      state.user = data;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;

      socket.auth = { token: data.accessToken };
      socket.open();

      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem(
        "tempUser",
        JSON.stringify({
          username: data.username,
          displayName: data.displayName,
          avatar: data.profile.avatar,
        })
      );
    },
    saveSessionUser(state, data) {
      state.user = data;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;

      socket.auth = { token: data.accessToken };
      socket.open();

      sessionStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem(
        "tempUser",
        JSON.stringify({
          username: data.username,
          displayName: data.displayName,
          avatar: data.profile.avatar,
        })
      );
    },
    logout(state, data) {
      state.user = undefined;
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("user");
      if (!data?.expired) localStorage.removeItem("tempUser");
      sessionStorage.removeItem("user");
      // router.push({ name: "Sign", params: { force: "1" } });
    },
    updateAccessToken(state, data) {
      let user = JSON.parse(sessionStorage.getItem("user") as string);
      if (user) {
        user.accessToken = data;
        sessionStorage.setItem("user", JSON.stringify(user));
      }

      user = JSON.parse(localStorage.getItem("user") as string);
      if (user) {
        user.accessToken = data;
        localStorage.setItem("user", JSON.stringify(user));
      }
      socket.auth = { token: data };
      socket.open();

      state.accessToken = data;
    },
    updateRefreshToken(state, data) {
      let user = JSON.parse(sessionStorage.getItem("user") as string);
      if (user) {
        user.refreshToken = data;
        sessionStorage.setItem("user", JSON.stringify(data));
      }

      user = JSON.parse(localStorage.getItem("user") as string);
      if (user) {
        user.refreshToken = data;
        localStorage.setItem("user", JSON.stringify(data));
      }

      state.refreshToken = data;
    },
    editUser(state, data) {
      if (data.setting.includes(".")) {
        Vue.set(
          (state.user as any)[`${data.setting.split(".")[0]}`],
          data.setting.split(".")[1],
          data.value
        );
      } else {
        Vue.set(state.user as object, data.setting, data.value);
      }

      // state.user = data.value
      if (localStorage.getItem("user"))
        return localStorage.setItem("user", JSON.stringify(state.user));
      // console.log(user, "<= user");
      if (sessionStorage.getItem("user"))
        return sessionStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  actions: {
    loadUser({ commit, getters }) {
      const user = getters.localUser;
      commit("setUser", user);
    },
  },
  modules: {},
  plugins: [createMultiTabState()],
});
