
import { defineComponent } from "vue";
import { MenuButtonStyles } from ".";

export default defineComponent({
  name: "MenuButton",

  props: {
    primary: Boolean,
    success: Boolean,
    error: Boolean,
    warning: Boolean,
    info: Boolean,
    secondary: Boolean,

    disabled: Boolean,
    submenu: Boolean,
    block: Boolean,
    icon: String,
    to: String,
    active: Boolean,
  },

  methods: {
    clicked() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit("click");
      }
    },

    getButtonStyle(): string {
      if (this.primary) return MenuButtonStyles.Primary;
      if (this.success) return MenuButtonStyles.Success;
      if (this.error) return MenuButtonStyles.Error;
      if (this.warning) return MenuButtonStyles.Warning;
      if (this.info) return MenuButtonStyles.Info;
      if (this.secondary) return MenuButtonStyles.Secondary;

      return MenuButtonStyles.Primary;
    },

    getButtonClassnames(): string {
      const classes = [this.getButtonStyle()];

      if (this.block) classes.push("block");
      if (this.active) classes.push("active");

      return classes.join(" ");
    },
  },
});
